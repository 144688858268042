import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { RxCross1 } from "react-icons/rx"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useRef } from "react"
import * as z from "zod"

export default function ReviewForm(props) {
  const form = useRef()

  const schema = z.object({
    name: z.string().min(1, "Name is required"),
    rating: z
      .number()
      .min(1, "Rating must be atleast 1")
      .max(5, "Rating must be at most 5"),
    review: z.string().min(1, "Review is required").max(350),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const createReview = useMutation({
    mutationFn: (data) => {
      return axios.post("https://steller-door-backend-ten.vercel.app/api/reviews", data)
    },
  })

  const sendReview = async (data) => {
   
    try {
      await createReview.mutateAsync(data)
      console.log("Review submitted successfully")
      props.handleClose()
    } catch (error) {
      console.error("Error submitting review:", error)
    }
  }

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.handleClose}>
        <RxCross1
          className="absolute top-5 right-5 cursor-pointer"
          onClick={props.handleClose}
        />
        <DialogTitle>Write a Review</DialogTitle>
        <DialogContent className="space-y-5 min-w-80 md:min-w-96 mt-8">
          <form ref={form} onSubmit={handleSubmit(sendReview)}>
            <div className="mb-5">
              <label
                htmlFor="name"
                className="mb-3 block font-medium text-[#07074D]"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
                {...register("name")}
              />
              {errors.name && (
                <p className="text-red-600">{errors.name.message}</p>
              )}
            </div>
            <div className="mb-5">
              <label
                htmlFor="rating"
                className="mb-3 block font-medium text-[#07074D]"
              >
                Rating
              </label>
              <select
                name="rating"
                id="rating"
                className="w-full rounded-md border border-[#e0e0e0] text-gray-500 bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
                {...register("rating", { valueAsNumber: true })}
              >
                <option value="0">Select Rating</option>
                {[1, 2, 3, 4, 5].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              {errors.rating && (
                <p className="text-red-600">{errors.rating.message}</p>
              )}
            </div>

            <div className="mb-5">
              <label
                htmlFor="review"
                className="mb-3 block font-medium text-[#07074D]"
              >
                Review
              </label>
              <textarea
                rows="4"
                name="review"
                id="review"
                placeholder="Share your review here.."
                className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
                {...register("review")}
              ></textarea>
              {errors.review && (
                <p className="text-red-600">{errors.review.message}</p>
              )}
            </div>
            {/* {successMessage && (
              <p className="text-green-600 text-center">{successMessage}</p>
            )} */}
            {/* {errorMessage && (
              <p className="text-red-600 text-center">{errorMessage}</p>
            )} */}
            <input
              className={`mt-4 w-full hover:shadow-form rounded-md py-3 px-8 font-semibold text-white outline-none cursor-pointer ${
                createReview.isPending ? "bg-gray-500" : "bg-[#276897]"
              }`}
              type="submit"
              value={createReview.isPending ? "Sending..." : "Send"}
              disabled={createReview.isPending}
            />
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
