import React from "react";
import  ReviewForm  from "./ReviewForm";

function ReviewDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
      };

  return (
    <div>
      <button onClick={handleClickOpen} className="h-fit py-2 px-6 text-white font-inter">
        Write a review
      </button>
      <ReviewForm open={open} handleClickOpen={handleClickOpen} handleClose={handleClose}/>
    </div>
  );
}

export default ReviewDialog;
