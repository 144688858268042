import React from "react"
import Hero from "../components/AboutComponent/Hero"
import Review from "../components/AboutComponent/Review"

import Map from "../components/Map"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import ReviewDialog from "../components/ReviewComponents/ReviewDialog"
import "react-slideshow-image/dist/styles.css"
import { Slide } from "react-slideshow-image"

const fetchReviews = async () => {
  const response = await axios.get("https://steller-door-backend-ten.vercel.app/api/reviews")
  return response.data
}


const About = () => {
  const { data } = useQuery({ queryFn: () => fetchReviews() })

  return (
    <div id="about" className="max-container">
      <Hero />
      <div className="flex justify-between items-center mt-10 md:mt-20 ">
        <h1 className=" text-2xl md:text-4xl font-donegal font-medium  p-4 md:p-0">
          Reviews
        </h1>
        <ReviewDialog />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-stretch">
      
        {data ? (
          data.map((item) => (
            <Review
              key={item.id}
              rating={item.rating}
              name={item.name}
              review={item.body}
            />
          ))
        ) : (
          <div>Loading...</div>
        )}
    
      </div>
      <Map />
    </div>
  )
}

export default About
