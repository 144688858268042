import React from "react"
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5"

const Review = (props) => {
  const { rating, name, review } = props
  const maxRating = 5
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 !== 0

  return (
    <div className="bg-[#eeeeeed4] pb-6 flex flex-col max-w-md h-full md:min-h-30 rounded overflow-hidden shadow-md mt-10">
      <div className="flex justify-between mx-7 my-5">
        <div className="text-2xl mb-2 font-donegal">{name}</div>
        <div className="flex gap-2">
          {Array(fullStars)
            .fill()
            .map((_, index) => (
              <IoStar key={index} className="text-orange-400" />
            ))}
          {hasHalfStar && <IoStarHalf className="text-orange-400" />}
          {Array(maxRating - fullStars - (hasHalfStar ? 1 : 0))
            .fill()
            .map((_, index) => (
              <IoStarOutline
                key={index + fullStars + (hasHalfStar ? 1 : 0)}
                className="text-gray-400"
              />
            ))}
        </div>
      </div>
      <div className="px-6 pb-4">
        <p className="text-gray-700 font-inter">{review}</p>
      </div>
    </div>
  )
}

export default Review
